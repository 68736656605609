.custom-select {
    position: relative;
  }
  
  .select-input {
    border: 1px solid #ccc;
    padding: 5px;
    cursor: pointer;
  }
  
  .select-input.open {
    border-color: blue;
  }
  
  .options-container {
    max-height: 150px;
    overflow-y: auto;
  }
  
  .options-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .option {
    padding: 5px;
    cursor: pointer;
  }
  
  .option.selected {
    background-color: lightblue;
  }
  