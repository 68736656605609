.mrd-notification {
    padding: 20px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .notification-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .notification-table th, .notification-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .notification-table th {
    background-color: #f4f4f4;
  }
  
  .notification-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .notification-table button {
    padding: 12px 24px; 
    font-size: 16px; 
    border: none;
    border-radius: 5px;
    cursor: pointer;

    transition: background-color 0.3s ease; 
  }
  
  .notification-table button.approve {
    background-color: #4CAF50;
  }
  
  .notification-table button.reject {
    background-color: #f44336;
  }
  
  .notification-table button:hover {
    opacity: 0.9; /* Slightly transparent on hover */
  }
  
  .notification-table button + button {
    margin-left: 10px; 
  }
  