.notification-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease, transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.notification-popup.show {
    opacity: 1;
    pointer-events: auto;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(62, 35, 35, 0.5); /* Semi-transparent overlay */
}

.dialog-container {
    background-color: #333030; /* Dark background */
    color: white; /* Text color */
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Shadow effect */
    padding: 20px;
    max-width: 600px;
    width: 80%;
    height: auto; /* Adjust height as needed */
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: space-between; /* Align items in the container space between them */
    position: relative;
    z-index: 1100;
}

.dialog-content {
    text-align: center; /* Center align all content */
}

.dialog-header {
    margin-bottom: 20px;
}

.dialog-header h2 {
    margin: 0;
    font-size: 24px;
}

.dialog-header p {
    margin: 0;
    font-size: 16px;
    color: #ccc; /* Light gray text */
}

.message {
    font-size: 18px;
    margin-bottom: 20px;
}

.hospital-info {
    margin-bottom: 20px; /* Space between message and hospital info */
}

.button-container {
    display: flex;
    justify-content: center; /* Center buttons horizontally */
    gap: 20px; /* Space between the buttons */
    margin-top: 20px; /* Optional: space above the buttons */
}

.dialog-button {
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px; /* Optional: rounded corners for the button */
    transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.dialog-button.primary {
    background-color: #4CAF50; /* Green button */
    color: white;
}

.dialog-button.primary:hover {
    background-color: #45a049; /* Darker green on hover */
}

.dialog-button.secondary {
    background-color: #f44336; /* Red button */
    color: white;
}

.dialog-button.secondary:hover {
    background-color: #e53935; /* Darker red on hover */
}

/* Optional: If you want to give them a more "button-like" appearance */
.dialog-button:active {
    transform: scale(0.98); /* Slight shrinking effect on click */
}


.case-view-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
}

.toast {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #27f82e;
    color: rgb(19, 18, 18);
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: none; /* Initially hide the toast */
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

.toast p {
    margin: 0;
    font-size: 16px;
}

.rejection-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.rejection-input-container select {
    padding: 10px;
    margin-bottom: 20px; /* Added spacing below the select box */
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%; /* Full width of the container */
    max-width: 400px; /* Maximum width for better control */
    background-color: #dcd2d2; /* Background color */
    color: #333; /* Text color */
    cursor: pointer;
}

.rejection-button-container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Gap between confirm and cancel buttons */
}

.dialog-button.primary {
    background-color: #4CAF50; /* Green button */
    color: white;
}

.dialog-button.secondary {
    background-color: #f44336; /* Red button */
    color: white;
}